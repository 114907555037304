<script>
import {defineComponent} from "vue";
import axios from "axios";
import AddCodeModal from "@/views/pages/applications/components/AddCodeModal.vue";
import PreOrderApplications from "@/views/pages/pre-orders/components/PreOrderApplications.vue";
import PreOrderFiles from "@/views/pages/pre-orders/components/PreOrderFiles.vue";
import Swal from "sweetalert2";
import NoData from "@/components/custom/NoData.vue";
import RevertToReview from "./components/RevertToReview.vue"

export default defineComponent({
  name: "ApprovedTab",
  data() {
    return {
      tabl_url: "/pre_order/list/",
      table_headers: [
        {
          label: "company",
          field: "company",
          align: "center",
        },
        {
          label: "departure",
          field: "departure",
          align: "center",
        },
        {
          label: "destination",
          field: "destination",
          align: "center",
        },
        {
          label: "loading type",
          field: "loading_type",
          align: "center",
          searchType: "select",
          searchOptions: [
            {label: "All", value: ""},
            {label: "Container", value: "container"},
            {label: "Wagon", value: "wagon"},
            {label: "Empty", value: "empty"},
          ],
        },
        {
          label: "progress status",
          field: "progress_status",
          align: "center",
        },
        {
          label: "pdf",
          field: "pdf",
          align: "center",
        },
        {
          label: "quantity",
          field: "quantity",
          align: "center",
        },
        {
          field: "agreed_rate",
          label: "rate",
          align: "center",
        },
        {
          field: "actions",
          label: "actions",
          align: "center",
        },
      ],
      pre_orders: [],
      orderInfo: {},
      preOrderId: null,
      currentApplication: {},
      is_loading: true,

      revert_order_id: 0
    };
  },
  methods: {
    downloadFile(filepath) {
      let url = process.env.VUE_APP_ORDER_URL + filepath;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
            this.is_downloading_file = false;
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
    async getPreOrders() {
      try {
        this.is_loading = true;
        let response = await axios.get("/pre_order/list/approved/");
        this.pre_orders = response.data.results;
      } catch {
        this.pre_orders = [];
      }
      this.is_loading = false;
    },
    addCode(application, order) {
      application.quantity = order.quantity;
      application.pre_order_order_number = parseInt(order.order.order_number);
      application.order_type = order.order.child_type;
      application.agreed_rate = order.agreed_rate;
      this.currentApplication = application;
    },
    canUserCompleteOrder(order) {
      try {
        if (order.applications.length === 0) return false
        return (
            order.applications.reduce((a, b) => a + b.code_count, 0) ===
            order.quantity * order.applications.length
        );
      } catch {
        return false
      }
    },
    async completeOrder(pre_order) {
      let Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
      try {
        await axios.post(`/pre_order/complete_pre_order/${pre_order.id}/`);
        await this.getPreOrders();
        await Toast.fire({
          icon: "success",
          title: "Order completed successfully",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "An error occurred",
        });
      }
    },
    getOrderChildType(loading_type) {
      switch (loading_type) {
        case "container":
          return "orders_container_detail";
        case "wagon":
          return "orders_wagon_detail"
        case "wagon_empty":
          return "orders_empty_wagon_detail"
        default:
          return "";
      }
    },
  },
  created() {
    this.getPreOrders();
  },
  components: {
    AddCodeModal,
    PreOrderApplications,
    PreOrderFiles,
    NoData,
    RevertToReview
  },
});
</script>

<template>
  <AddCodeModal :application="currentApplication" @update="getPreOrders()"/>
  <RevertToReview ref="reverter" @reverted="getPreOrders()"/>
  <b-spinner v-if="is_loading" variant="info"></b-spinner>

  <b-row v-else-if="!is_loading">
    <template v-if="pre_orders.length > 0">
      <b-col sm="6" lg="4" v-for="order in pre_orders" :key="order">
        <b-card no-body>
          <b-card-body>
            <div class="d-flex mb-3">
              <div class="flex-shrink-0 avatar-sm">
                <div class="avatar-title bg-light rounded">
                  <h5 class="mb-0 fw-semibold text-secondary">
                    {{ order.order.order_number }}
                  </h5>
                </div>
              </div>
              <div class="flex-grow-1 ms-3 text-truncate">
                <h5 class="fs-15 mb-1 text-truncate">
                  {{ order.company.name }}
                </h5>
                <p class="text-muted mb-0">Customer</p>
              </div>
            </div>

            <div>

              <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                   <span style="font-size: 1rem">
                     <span class="text-danger me-1">
                        <font-awesome-icon :icon="['fas', 'location-dot']"/>
                     </span>
                     <span class="ms-1">{{ order.departure ? order.departure.name : '--' }}</span>
                   </span>
                </div>
                <div>
                   <span style="font-size: 1rem">
                     <span class="text-success me-1">
                        <font-awesome-icon :icon="['fas', 'location-dot']"/>
                     </span>
                     <span class="ms-1">{{ order.destination ? order.destination.name : '--' }}</span>
                   </span>
                </div>
              </div>

              <div>
                <ul class="nav nav-pills arrow-navtabs nav-success bg-light mb-3" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" :href="`#pre-order-applications-` + order.id" target="_self"
                       data-bs-toggle="tab" role="tab">
                      <span class="d-block d-sm-none">
                        <i class="mdi mdi-home-variant"></i>
                      </span>
                      <span class="d-none d-sm-block">Applications</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" :href="`#pre-order-files-` + order.id" target="_self" data-bs-toggle="tab"
                       role="tab">
                      <span class="d-block d-sm-none">
                        <i class="mdi mdi-home-variant"></i>
                      </span>
                      <span class="d-none d-sm-block">Files ({{
                          order.files ? order.files.length : 0
                        }})</span>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="tab-content text-muted">
                <div class="tab-pane active" :id="`pre-order-applications-` + order.id" role="tabpanel">
                  <PreOrderApplications :applications="order.applications" :order_quantity="order.quantity"
                                        @addCode="addCode($event, order)"/>
                </div>
                <div class="tab-pane" :id="`pre-order-files-` + order.id" role="tabpanel">
                  <PreOrderFiles :pre_order_id="order.id" :pre_order_order_number="parseInt(order.order.order_number)"
                                 :files="order.files ? order.files : []" @update="getPreOrders()"/>
                </div>
              </div>
              <div class="d-flex align-items-center gap-3">
                <b-button variant="outline-danger" class="w-100" @click="$refs.reverter.revertOrder(order.id)">
                  Revert
                </b-button>
                <div class="w-100">
                  <b-button class="w-100" variant="success" v-if="canUserCompleteOrder(order)"
                            @click="completeOrder(order)">
                    Complete {{ order.loading_type ? order.loading_type.replace(/_/g, " ").toUpperCase() : "" }} Order
                  </b-button>
                  <b-button class="w-100" variant="success" v-else disabled>
                    Complete Order
                  </b-button>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </template>
    <template v-else>
      <NoData>No Approved Orders Found</NoData>
    </template>
  </b-row>
</template>

<style scoped></style>
