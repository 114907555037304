<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "PreOrderFiles",
  emits: ['update'],
  props: {
    files: {
      type: Array,
      default: () => [],
      required: true
    },
    pre_order_id: {
      type: Number || String,
      default: '',
      required: true
    },
    pre_order_order_number: {
      type: Number || String,
      required: true
    }
  },
  data() {
    return {
      name: '',
      file: null,
      delete_confirmation_file: {}
    }
  },
  computed: {
    order_files() {
      return this.files
    }
  },
  methods: {
    async createFile() {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000
      })
      try {
        let formData = new FormData()
        formData.append('pre_order_id', this.pre_order_id)
        formData.append('file', this.file)
        formData.append('name', this.name)

        await axios.post('/pre_order/file/create/', formData)
        this.$emit('update')
        document.querySelector(`#CreatePreOrder${this.pre_order_id}FileModal .btn-close`).click()
        this.name = ''
        this.file = null
        await Toast.fire({
          icon: 'success',
          title: 'File created successfully'
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'An error occurred'
        })
      }
    },
    async deleteFile(file) {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000
      })
      try {
        await axios.delete(`/pre_order/file/delete/${file.id}/`)
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'File deleted successfully'
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'An error occurred'
        })
      }
    },
    async updateFileName(file) {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000
      })
      try {
        await axios.put(`/pre_order/file/update/${file.id}/`, {
          name: file.name
        })
        await Toast.fire({
          icon: 'success',
          title: 'File name updated successfully'
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'An error occurred'
        })
      }
    },
    onFileSelected(event) {
      this.file = event.target.files[0]
    },
    downloadFile(path) {
      let url = process.env.VUE_APP_ORDER_URL + path
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
  }
})
</script>

<template>
  <table class="table table-borderless mb-0 table-sm " v-if="pre_order_id">
    <tbody>
    <tr>
      <td colspan="2">
        <div>
          <h6 data-bs-toggle="modal" :data-bs-target="`#CreatePreOrder${pre_order_id}FileModal`"
              class="d-inline-block text-secondary fw-semibold cursor-pointer py-2">
            + Upload File
          </h6>
        </div>
      </td>
    </tr>
    <tr class="align-middle" v-for="file in order_files" :key="file">
      <th scope="row" class="overflow-hidden">
                     <span class="me-1">
                        <i class="mdi mdi-file fs-16 align-middle"></i>
                     </span>
        <span class="mb-0">{{ file.name }}</span>
      </th>
      <td class="text-end">

        <b-button-group
            v-if="delete_confirmation_file.id !== file.id"
            class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example"
        >

          <b-button variant="light" @click="downloadFile(file.file)">
            <i class="bx bxs-download align-bottom"></i>
          </b-button>

          <div class="dropdown">
            <b-button variant="light" class="p-0 h-100" data-bs-toggle="dropdown">
              <div class="px-1">
                <i class="ri-pencil-fill align-bottom px-1"></i>
              </div>
            </b-button>
            <div class="dropdown-menu dropdown-menu-end border border-success border-2" style="min-width: 300px">
              <form @submit.prevent="updateFileName(file)" class="d-flex justify-content-between pe-3">
                <input class="form-control border-0" placeholder="File name" v-model="file.name">
                <i class="mdi mdi-check-circle cursor-pointer text-success pt-1 fs-22"></i>
              </form>
            </div>
          </div>

          <b-button variant="light" @click="delete_confirmation_file = file">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>

        </b-button-group>

        <b-button-group
            v-else-if="delete_confirmation_file.id === file.id"
            class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example"
        >
          <b-button variant="danger" @click="deleteFile(file)">
            Confirm & Delete
          </b-button>
          <b-button variant="light" @click="delete_confirmation_file = {}">
            Cancel
          </b-button>
        </b-button-group>

      </td>
    </tr>
    </tbody>
  </table>

  <h6 v-else class="mb-3">
    No Files
  </h6>

  <div :id="`CreatePreOrder${pre_order_id}FileModal`" class="modal fade" tabindex="-1" aria-hidden="true"
       style="display: none;  z-index: 9999 !important;">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Upload File
            <span class="badge badge-gradient-secondary ms-1">
                {{ pre_order_order_number }}
            </span>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="createFile()">
            <div class="mb-3">
              <label class="form-label text-dark">Name</label>
              <input v-model="name" class="form-control" placeholder="Enter name">
            </div>
            <div class="mb-4">
              <label class="form-label text-dark">Choose File</label>
              <input @input="onFileSelected" class="form-control" placeholder="Choose file" type="file">
            </div>
            <div class="">
              <b-button class="w-100" type="submit">
                Start Uploading
                <i class="mdi mdi-upload"></i>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>