<template>
  <button
      class="visually-hidden"
      data-bs-toggle="modal"
      data-bs-target="#addCodeModal"
      id="addCodeTriggerBtn"
  >
    Add code
  </button>

  <div
      id="addCodeModal"
      class="modal fade"
      tabindex="-1"
      aria-hidden="true"
      style="display: none"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Add codes to order
            <span class="badge badge-gradient-info"></span>
          </h5>
          <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
          ></button>
        </div>
        <div v-if="validation.failed">
          <b-alert show variant="warning" role="alert">
            <label class="form-label d-block mb-2">Duplicate codes found</label>
            <h5 v-for="code in validation.details" :key="`duplicate_${code}`" class="d-inline-block mb-0 me-2">
              <span class="badge badge-gradient-warning">
              {{ code }}
            </span>
            </h5>
          </b-alert>
        </div>
        <div class="p-4">
          <div class="row g-3 mb-3" v-for="(li, index) in list" :key="li">
            <div class="col-12 py-0 text-start" v-if="index === 1">
              <div>
                <span class="text-warning"
                ><i class="ri-alert-fill fs-5 my-0 me-2 align-middle"></i
                ></span>
                <span class="text-muted"
                >All the codes below will be considered as sub codes of the
                  code above.</span
                >
              </div>
            </div>
            <div class="col-2">
              <label for="prefixInput" class="form-label">Prefix</label>
              <input
                  v-model="li.prefix"
                  type="text"
                  class="form-control"
                  id="prefixInput"
                  placeholder="Prefix"
              />
            </div>
            <div class="col-3">
              <label for="startInput" class="form-label">Start</label>
              <span class="text-danger ms-1">*</span>
              <input
                  v-model="li.start"
                  type="text"
                  class="form-control"
                  id="startInput"
                  placeholder="Start"
              />
            </div>
            <div class="col-3">
              <label for="endInput" class="form-label">End</label>
              <span class="text-danger ms-1">*</span>

              <div @dblclick="li.manualEnd = false">
                <input
                    v-if="li.manualEnd"
                    v-model="li.end"
                    type="number"
                    class="form-control"
                    id="endInput"
                    :placeholder="
                    li.start
                      ? parseInt(li.start) + application.quantity - 1
                      : 'Manual end'
                  "
                    :class="getClassName(index)"
                />
              </div>

              <div @dblclick="li.manualEnd = true">
                <input
                    v-if="!li.manualEnd"
                    type="number"
                    class="form-control"
                    id="endInput"
                    :value="
                    li.start
                      ? parseInt(li.start) + application.quantity - 1
                      : ''
                  "
                    placeholder="Double click to enter manually"
                    :class="getClassName(index)"
                    disabled
                />
              </div>
            </div>
            <div class="col-md-3">
              <label for="territory" class="form-label">Territory</label>
              <span class="text-danger ms-1">*</span>
              <Multiselect
                  v-model="li.territory"
                  :close-on-select="true"
                  :searchable="true"
                  :options="territories"
                  required
                  :placeholder="
                  territories.length
                    ? 'Select territory'
                    : 'No territories available'
                "
              ></Multiselect>
            </div>
            <div class="col-1 text-center">
              <label for="endInput" class="form-label d-block">Delete</label>
              <b-button @click="removeList(index)" variant="danger">
                <font-awesome-icon
                    icon="fa-solid fa-trash"
                    class="c_icon fs-6"
                />
              </b-button>
            </div>
          </div>

          <div class="row justify-content-start mt-4 pb-3 border-bottom mb-3">
            <div class="col">
              <div class="input-step step-primary">
                <button type="button" class="plus" @click="addList()">+</button>
                <input
                    type="number"
                    class="product-quantity"
                    v-model="quantity"
                    min="0"
                    readonly
                />
              </div>
            </div>
          </div>

          <div class="w-100 text-center">
            <button
                v-if="formValid && validation.done"
                @click="addCode()"
                class="btn btn-success w-100"
            >
              + Add
            </button>
            <button
                v-else
                class="btn btn-success w-100"
                :disabled="!formValid"
                @click="validateCode()"
            >
              Validate
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import axios from "axios";

export default {
  emits: ["update"],
  name: "AddCodeModal",
  data() {
    return {
      modalShow: false,
      quantity: 1,
      customer: null,
      is_validated: false,
      validation_failed: false,
      validation: {
        done: false,
        failed: false,
        message: '',
        details: ''
      },
      list: [
        {
          prefix: "",
          start: "",
          end: "",
          territory: "",
          manualEnd: false,
        },
      ],
      territories: [],
    };
  },
  props: {
    application: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    Multiselect,
  },
  methods: {
    addList() {
      this.list.push({
        prefix: "",
        start: "",
        end: "",
        territory: "",
        manualEnd: false,
      });
      this.quantity++;
    },

    removeList(index) {
      if (index === 0) {
        Swal.fire({
          icon: "warning",
          title: "Warning...",
          text: "You cannot delete the main code!",
          showConfirmButton: false,
          timer: 3000,
        });
      } else {
        this.list.splice(index, 1);
        this.quantity--;
      }
    },

    getClassName(index) {
      if (this.list[index].start && this.list[index].end) {
        if (
            parseInt(this.list[index].end) -
            parseInt(this.list[index].start) +
            1 >
            this.application.quantity &&
            this.list[index].manualEnd
        ) {
          return "form-control border-danger";
        } else {
          if (
              parseInt(this.list[index].end) < parseInt(this.list[index].start)
          ) {
            return "form-control border-danger";
          }
        }
      } else {
        return "form-control";
      }
    },

    async getTerritories() {
      let request = await fetch(
          `${process.env.VUE_APP_ORDER_URL}/core/territories/`
      );
      let response = await request.json();
      this.territories = response["results"].map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
    async validateCode() {
      this.validation.done = false;
      this.validation.failed = false;
      const range = this.list.slice(0, 1).map((i) => {
        return {
          start: parseInt(i.start),
          end: i.manualEnd
              ? i.end
              : parseInt(i.start) + this.application.quantity - 1,
        };
      })[0];
      let codes = [];
      for (let i = range.start; i <= range.end; i++) {
        codes.push(i);
      }
      try {
        await axios.post(`/code/validate/${this.application.forwarder.id}/`, {
          codes: codes
        })
        this.validation.done = true;
        this.validation.failed = false;
        return true;
      } catch (e) {
        this.validation.message = e.response.data.message;
        this.validation.details = e.response.data.data;
        this.validation.failed = true;
        return false;
      }
    },
    async addCode() {
      let form = {
        main_codes: this.list.slice(0, 1).map((i) => {
          return {
            prefix: i.prefix,
            start_code: parseInt(i.start),
            end_code: i.manualEnd
                ? i.end
                : parseInt(i.start) + this.application.quantity - 1,
            territory_id: i.territory,
          };
        })[0],
        sub_codes: this.list.slice(1, this.list.length).map((i) => {
          return {
            prefix: i.prefix,
            start_code: parseInt(i.start),
            end_code: i.manualEnd
                ? i.end
                : parseInt(i.start) + this.application.quantity - 1,
            territory_id: i.territory,
          };
        }),
      };

      if (this.application.pre_order_order_number) {
        form["order"] = {
          order_number: this.application.pre_order_order_number,
          child_type: this.application.order_type,
        };
      }

      try {
        await fetch(
            `${process.env.VUE_APP_ORDER_URL}/code/create_range/${this.application.id}/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(form),
            }
        );
        this.$emit("update");
        this.resetForm();

        document.querySelector(`#addCodeModal .btn-close`).click()

        await Swal.fire({
          title: "Success",
          text: "Code added successfully",
          icon: "success",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        });
      } catch {
        await Swal.fire({
          title: "Error",
          text: "Something went wrong",
          icon: "error",
          showCancelButton: false,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },

    resetForm() {
      this.list = [
        {
          prefix: "",
          start: "",
          end: "",
          territory: "",
          manualEnd: false,
          territoryList: [],
        },
      ];
      this.quantity = 1;
    },
  },
  computed: {
    formValid: {
      get() {
        let errors = 0;
        this.list.forEach((item) => {
          if (item.territory === "") {
            errors++;
          }
          if (item.start === "") {
            errors++;
          }
          if (item.manualEnd) {
            if (item.end === "") {
              errors++;
            }
            if (parseInt(item.end) < parseInt(item.start)) {
              errors++;
            }
            if (
                parseInt(item.end) - parseInt(item.start) + 1 >
                this.application.quantity
            ) {
              errors++;
            }
          }
        });

        let duplicate_free_territories = new Set(this.list.map((i) => i.territory));
        if (duplicate_free_territories.size !== this.list.length) {
          errors++;
        }
        return errors === 0;
      },
    },
  },
  watch: {
    application: {
      handler(newValue) {
        this.resetForm();
        if (newValue && newValue.agreed_rate) {
          this.agreed_rate = newValue.agreed_rate || 0;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    await this.getTerritories();
  },
};
</script>

<style scoped></style>
