<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PreOrderApplications",
  emits: ['addCode'],
  props: {
    applications: {
      type: Array,
      default: () => [],
      required: true
    },
    order_quantity: {
      type: Number,
      default: 0,
      required: true
    }
  },
  computed: {
    order_applications() {
      return this.applications
    }
  },
  methods: {
    calculateCodesRequired(application) {
      let count = this.order_quantity - application.code_count
      if (count > 0) {
        return 'Add ' + count + ' more codes'
      } else {
        return 'You must remove ' + Math.abs(count) + ' codes'
      }
    }
  }
})
</script>

<template>
  <div class="table-responsive mb-3">
    <table class="table table-borderless mb-0 table-sm">
      <tbody v-if="order_applications.length > 0">
      <tr class="align-middle" v-for="application in order_applications"
          :key="`application_` + application.id">
        <th scope="row">
                     <span class="me-1">
                        <i class="mdi mdi-file fs-16 align-middle"></i>
                     </span>
          {{ application.forwarder.name }}
          <router-link :to="{name: 'application_update', params: {id: application.id}}">
                      <span class="badge badge-soft-success ms-2">
                        <span>{{ application.number }}</span>
                      </span>
          </router-link>
        </th>
        <td>
          <small>{{ application.code_count }} / {{ order_quantity }}</small>
        </td>
        <td class="text-end">
          <VTooltip>
            <b-button
                data-bs-toggle="modal" data-bs-target="#addCodeModal"
                @click="this.$emit('addCode', application)"
                variant="outline-success" size="sm">+ Add code</b-button>
            <template v-slot:popper>
              <span>{{ calculateCodesRequired(application) }}</span>
            </template>
          </VTooltip>
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr>
        <td colspan="2" class="text-muted">No Applications</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>

</style>