<template>
    <b-modal v-model="show_modal" hide-footer title="Revert to Under Review" dialog-class="modal-fullscreen-sm-down"
        class="v-modal-custom" hide-header-close size="lg">

        <b-alert show variant="danger" role="alert">
            <strong class="fs-5">
                <i class="ri-alert-line me-2 align-middle"></i>
                This will permanently delete the order and the following applications with their codes
            </strong>
        </b-alert>

        <form class="pt-3" @submit.prevent="startReverting()">
            <div class="d-flex align-items-start text-muted mb-4">
                <div class="flex-shrink-0 avatar-md me-3">
                    <div class="avatar-title bg-light rounded">
                        <h4 class="mb-0 fw-semibold text-secondary">
                            {{ order_number }}
                        </h4>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5>
                        {{ pre_code_order }}
                    </h5>
                    <p class="fs-5">
                        This will <span class="text-danger fw-bold">permanently delete</span> the order with above number
                    </p>
                </div>
            </div>

            <div class="mb-4">
                <h5 class="mb-3">Applications</h5>
                <div v-for="application in applications" :key="`application_${application.id}`">
                    <h4><b-badge variant="secondary">Application: {{ application.name }}</b-badge></h4>
                    <div>
                        <Span class="text-muted">Codes:</Span>
                        <span class="badge bg-secondary-subtle text-secondary fs-14" v-for="code in application.codes"
                            :key="`code_${code.id}`">
                            {{ code.name }}
                        </span>
                    </div>
                </div>
            </div>

            <div>
                <b-button v-if="!reverting" type="submit" variant="danger" class="w-100">
                    Confirm & Delete
                </b-button>
                <b-button v-else-if="reverting" type="button" variant="danger" class="w-100" disabled>
                    Reverting ...
                </b-button>
            </div>
        </form>
    </b-modal>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
    emits: ["reverted"],
    data() {
        return {
            show_modal: false,
            pre_order_id: null,
            summary: {},

            reverting: false
        }
    },
    methods: {
        async revertOrder(pre_order_id) {
            await this.showOrderSummaryBeforeRevert(pre_order_id)
        },
        async showOrderSummaryBeforeRevert(pre_order_id) {
            try {
                let response = await axios.get(`/pre_order/revert/${pre_order_id}/detail/`)
                this.summary = response.data
                this.pre_order_id = pre_order_id
                this.show_modal = true
            } catch {
                alert("Couldn't get order summary")
            }
        },
        async startReverting() {
            try {
                this.reverting = true
                await axios.post(`/pre_order/revert/${this.pre_order_id}/`)
                this.$emit("reverted")
                this.show_modal = false
                this.reverting = false
                await Swal.fire({
                    icon: "success",
                    title: "Order reverted successfully",
                    showConfirmButton: false,
                    timer: 1500
                })
            } catch {
                alert("Couldn't revert the order")
            }
        }
    },
    computed: {
        pre_code_order() {
            if (this.summary.deletion_summary) {
                return this.summary.deletion_summary.PreCodeOrder[0].name || ""
            }
            return ""
        },
        order_number() {
            if (this.summary.deletion_summary) {
                return this.summary.deletion_summary.PreCodeOrder[0].order_number || "-"
            }
            return "-"
        },
        applications() {
            if (this.summary.deletion_summary) {
                return this.summary.deletion_summary.Application || []
            }
            return []
        }
    }
}

</script>
